<template>
  <checkout v-if="isGreen" />
  <checkout-white v-else-if="isWhite" />
  <checkout-vsl
    v-else-if="isVsl"
    :title="checkout.title"
    :btnText="checkout.button_text"
    :packages="checkout.packages"
  />
  <checkout-default v-else-if="isDefault" />
</template>

<script>
import { checkouts } from '@/data/constants'
import Checkout from './checkout/Checkout.vue'
import CheckoutWhite from './checkout-white/CheckoutWhite.vue'
import CheckoutVsl from './checkout-vsl/CheckoutVsl.vue'
import CheckoutDefault from '../views/QuizResults.vue'
export default {
  name: 'Checkouts',
  props: {
    checkoutName: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    checkout: {
      type: Object,
    },
  },
  components: { Checkout, CheckoutWhite, CheckoutDefault, CheckoutVsl },
  computed: {
    isGreen() {
      return this.checkoutName === checkouts.GREEN_MAIN
    },
    isWhite() {
      return this.checkoutName === checkouts.WHITE_MAIN
    },
    isDefault() {
      return this.checkoutName === checkouts.DEFAULT
    },
    isVsl() {
      return this.checkoutName === checkouts.VSL
    },
  },
}
</script>

<style scoped></style>

<template>
  <p v-if="localLoading">Loading ...</p>
  <checkouts v-else :checkout-name="checkout.lp_id" :isLoading="isLoading" />
</template>

<script>
import Checkouts from '@/components/Checkouts.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { checkoutTypes } from '../data/constants'
export default {
  components: { Checkouts },
  name: 'CheckoutUser',
  data() {
    return {
      localLoading: false,
    }
  },
  computed: {
    ...mapState('checkout', ['isLoading', 'checkout']),
    ...mapState(['user']),
  },
  created() {
    this.SET_CHECKOUT_TYPE(checkoutTypes.CHECKOUT)
    this.localLoading = true
    this.fetchCheckoutById(this.$route.params.checkoutId)
      .then((res) => {
        if (res.is_direct_to_sales) {
          this.$router.push(`/subscription/${this.$route.params.checkoutId}`)
        } else this.localLoading = false
      })
      .catch(() => (this.localLoading = false))
    if (!Object.keys(this.user).length) this.fetchUser()
  },
  beforeUnmount() {
    this.SET_CHECKOUT_TYPE(null)
  },
  methods: {
    ...mapActions('checkout', ['fetchCheckoutById']),
    ...mapMutations('checkout', ['SET_CHECKOUT_TYPE']),
    ...mapActions(['fetchUser']),
    ...mapMutations('subscription', ['SET_OPEN']),
  },
}
</script>

<style></style>

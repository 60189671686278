<template>
  <div class="paywall">
    <div class="paywall-bg"></div>
    <div class="container container--vsl">
      <div class="paywall-header">
        <router-link to="/">
          <img src="@/assets/icons/logo.svg" />
        </router-link>
      </div>
      <div class="paywall-body">
        <paywall-banner
          :title="title"
          :btnText="btnText"
          @clickButton="onScroll"
        />

        <paywall-timer @clickButton="onScroll" :btnText="btnText" />

        <paywall-info @clickButton="onScroll" :btnText="btnText" />

        <paywall-reviews
          :testimonials="statickTestimonials"
          title="Customer Reviews"
        />

        <paywall-lists hideHeader />

        <PurchaseDynamic
          :packages="packages"
          @onClose="onClose"
          ref="purchase"
          :initialOpen="!!$route.query.plan"
          :initialPackage="$route.query.plan"
          :redirect="redirectRoute"
          :btnText="btnText"
        />

        <paywall-guarantee />

        <!-- <paywall-ask :peopleAsk="peopleAsk" /> -->

        <paywall-footer />
      </div>
    </div>
  </div>
</template>

<script setup>
import PaywallBanner from '@/components/quiz/paywall/PaywallBanner.vue'
import PaywallTimer from '@/components/quiz/paywall/PaywallTimer.vue'
import PaywallInfo from '@/components/quiz/paywall/PaywallInfo.vue'
import PaywallLists from '@/components/quiz/paywall/PaywallLists.vue'
// import PaywallAsk from '@/components/quiz/paywall/PaywallAsk.vue'
import PaywallGuarantee from '@/components/quiz/paywall/PaywallGuarantee.vue'
import PaywallFooter from '@/components/quiz/paywall/PaywallFooter.vue'
import PurchaseDynamic from '@/components/subscribe/PurchaseDynamic.vue'
import PaywallReviews from '@/components/quiz/paywall/PaywallReviews.vue'
import { statickTestimonials } from '@/data/paywallData.js'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
// import { peopleAsk } from '@/data/paywallData'
import { useStore } from 'vuex'

const router = useRouter()
const props = defineProps(['title', 'btnText', 'packages'])
const store = useStore()

const redirectRoute = computed(() =>
  store.getters.getUserGoal?.length > 0 ? '/home' : '/quiz?redirect=Home'
)

const purchase = ref(null)

const onClose = () => {
  router.push('/home')
}

function onScroll() {
  const d = document.getElementById('packages')
  d.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  })
}

function handleOpenModal() {
  purchase.value?.onGetPlan()
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
.paywall {
  min-height: 100vh;

  &-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/img/paywall/stars.svg'),
      linear-gradient(293deg, #71b280 0%, #134e5e 100%);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  &-header {
    margin-bottom: 32px;
    padding-top: 36px;
  }

  @media (max-width: 767px) {
    &-header {
      & a {
        display: flex;
        justify-content: center;
        max-width: 144px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.users {
  margin-bottom: 104px;

  &-img {
    max-width: 800px;
    width: 100%;
    margin: 0 auto 32px;
  }

  &-button {
    display: flex;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    margin-bottom: 56px;
  }
}
</style>
